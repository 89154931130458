import React, {useState} from "react";
import { Grid,  Typography } from "@material-ui/core";

import Product from './Product'
import useStyles from "./productsStyles";


import FilterProduct from "./filterModel";



const Products = ({ products, productsTwo, productsThree }) => {
  const classes = useStyles();
 const [searchResult, setSearchResult] = useState([])
  


  return (
    <React.Fragment>
      <header align='center' style={{marginTop: '50px'}}> 
      <Typography variant = 'h2' style={{fontWeight: 'bold'}} className={classes.text}>
        VX Collection
      </Typography>
      <Typography variant='h5' className={classes.text} >
        Check out Monks in action in the 3D viewer!
      </Typography>
       {/* <img src={vxcollection} alt='vx collection header' height='200px' style={{marginBottom: '-50px'}}/> */}
      </header>
      <br />
      <main className={classes.content}>
        
        
       
        <FilterProduct searchResult={searchResult} setSearchResult={setSearchResult}  />
        
      
        
        {!searchResult.length && (
          <>
        
        <Grid container justifyContent="center" spacing={5}>
          {products.map((product) => (
            <Grid item key={product.id} xs={12} sm={12} md={4} lg={3}>
              <Product product={product} />
            </Grid>
          ))}
         
          {productsTwo.map((product) => (
            <Grid item key={product.id} xs={12} sm={12} md={4} lg={3}>
              <Product product={product} />
            </Grid>
          ))}
          
             
          {productsThree.map((product) => (
            <Grid item key={product.id} xs={12} sm={12} md={4} lg={3}>
              <Product product={product} />
            </Grid>
          ))}
        </Grid>
        </>
        )}
      </main>
    
    </React.Fragment>
  );
};

export default Products;