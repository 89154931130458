import React from "react";
import {
  Grid,
  Paper,
  Container,
  InputBase,
  IconButton,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { commerce } from "../../lib/commerce";
import Product from './Product';


const FilterProduct = ({searchResult, setSearchResult}) => {

    const [keyword, setKeyword] = React.useState("");
    const [resultMessage, setResultMessage] = React.useState("");

    const handleInputChange = (event) => {
        if (!keyword || !event.target.value) {
          setResultMessage("");
          setSearchResult([]);
          
        }
        setKeyword(event.target.value);
       
      };

      const onSubmit = async (e) => {
        e.preventDefault();
        if (!keyword) {
          setResultMessage("No text entered");
        }
        if (keyword) {
          try {
           
            const { data } = await commerce.products.list({
                limit: 200,
              query: keyword,
            });
            if (!data) {
              setResultMessage("No result match");
              setSearchResult([]);
              return;
            }
            setResultMessage("");
            setSearchResult(data);
          } catch (error) {
            setSearchResult([]);
          }
        }
      };
  return (
    <div align='center'>
      <Container >
      <Paper style ={{border: 'solid 2px', borderColor: '#CBFF2F', backgroundColor: 'black', width: '300px' }} component='form' onSubmit={onSubmit} >
      <InputBase
      style={{ margin: '5px', color: '#CBFF2F'}}
            className="input"
            onChange={handleInputChange}
            placeholder="Search"
            
          />
          <IconButton type='submit' aria-label='search' style={{color: '#CBFF2F'}} >
              <Search />
          </IconButton>
      </Paper>
      {resultMessage && <p className="result-message">{resultMessage}</p>}
      {searchResult.length && (
          <div>
            <Grid container spacing={4}>
              {searchResult.map((product) => (
                <Grid key={product.id} item xs={12} sm={6} md={4}>
                  <Product product={product}  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </Container>
    </div>
  );
};

export default FilterProduct;