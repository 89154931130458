import React, {useState, useEffect} from 'react';
import { CssBaseline,  useMediaQuery, useTheme} from '@mui/material';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { commerce } from './lib/commerce';


import { NavBar, Home, Footer, BottomNav,  Header, Products, Metaverses } from './components';




const App = () => {
    const [products, setProducts] = useState([]);
    const [productsTwo, setProductsTwo]= useState([]);
    const [productsThree, setProductsThree]= useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const fetchProducts = async () => {
        const { data } = await commerce.products.list({
          limit: 200,
          
        });
        setProducts(data);
    },

     fetchProductsTwo = async () => {
      const { data } = await commerce.products.list({
        limit: 200,
        page: 2,
        
      });
      setProductsTwo(data);
  }

  const fetchProductsThree = async () => {
    const { data } = await commerce.products.list({
      limit: 200,
      page: 3,
      
    });
    setProductsThree(data);
}
 
   
    useEffect(() => {
        fetchProducts();
        fetchProductsTwo();
        fetchProductsThree();
       
    }, [])
 
  return (
    <>
    <Router>
    <CssBaseline />
        <main style={{backgroundColor: 'black'}}>
        
       
          <div id='container' ></div>
      <Header></Header>
        <NavBar></NavBar>
        <Routes>

            <Route exact path='/' element={<Home></Home>}></Route>
            <Route exact path='/models' element={<Products products={products} productsTwo={productsTwo} productsThree={productsThree}></Products>}></Route>

            <Route exact path='/videos' element={<Metaverses></Metaverses>}></Route>
          
           
        </Routes>
    </main>
    
    <footer>
    {isMobile ? (
    
   <></>

    ) : (
     <Footer></Footer>
        
      )}
        <BottomNav></BottomNav>
    </footer>
    </Router>
    </>
  )
}

export default App