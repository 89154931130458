import React from 'react';
import { Suspense, iframe } from "react";
import { CssBaseline, Grid, Card, CardMedia, Typography } from '@material-ui/core';


import { Canvas } from "@react-three/fiber";
import { OrbitControls,  Stage, BakeShadows } from "@react-three/drei";
import Model from '../VXModel/animatedModel';

import textIntro from '../../assets/headerText.png';
import {useTheme, useMediaQuery} from '@material-ui/core';
import mobileBackground from '../../assets/mobileBackground.jpg'

import useStyles from '../VXModel/VXStyles';



const Header = () => {
  const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    let classes=useStyles();
  
    
  return (
      <>
   <CssBaseline />
<main >
   
{isMobile ? ( 
 <Grid container >
   
  <Grid item xs={12} sm={12} md={8} lg={8} >

 <Card className={classes.border} >


<Canvas style={{backgroundImage: `url(${mobileBackground})`, backgroundSize: '100%'}}  className={classes.canvas} shadows camera={{fov: 45, position: [0, 10, 340]}}>

     <OrbitControls   
     makeDefault
autoRotate
autoRotateSpeed={0.3}
maxPolarAngle={Math.PI / 2.3}
minPolarAngle={Math.PI / 2.3}
enableZoom
enablePan
enableRotate
/>
   
     <ambientLight intensity={0.03} />

     <directionalLight position={[-5, 5, 5]} receiveShadow shadow-mapSize-width={1024} shadow-mapSize-height={1024} />

     <Suspense fallback={null}>
     
     <Stage environment="city" intensity={1}>
    <Model />
      </Stage>
      <BakeShadows />
    
     
     </Suspense>

   </Canvas>


 </Card>

</Grid>
</Grid>
) : (
<Grid container >
   
    <Grid item xs={12} sm={12} md={6} lg={8} >

   <Card className={classes.border} >
  
  
 <Canvas style={{backgroundImage: `url(${mobileBackground})`, backgroundSize: '100%', height: '500px'}}  className={classes.canvas} shadows camera={{fov: 45, position: [0, 10, 340]}}>
 
       <OrbitControls   
       makeDefault
autoRotate
autoRotateSpeed={0.3}
maxPolarAngle={Math.PI / 2.3}
minPolarAngle={Math.PI / 2.3}
enableZoom
enablePan
enableRotate
/>
     
       <ambientLight intensity={0.03} />

       <directionalLight position={[-5, 5, 5]} receiveShadow shadow-mapSize-width={1024} shadow-mapSize-height={1024} />

       <Suspense fallback={null}>
       
       <Stage environment="city" intensity={1}>
      <Model />
        </Stage>
        <BakeShadows />
      
       
       </Suspense>

     </Canvas>


   </Card>

</Grid>


  <Grid item xs={12} sm={12} md={4} lg={3}>
  <CardMedia component='img' src={textIntro} alt='Crypto monks in 3d' height='100%' style={{marginLeft: '25px', width: '350px', marginTop: '-20px'}} />
  </Grid>
  </Grid>
  )}

</main>
</>
  )
};

export default Header; 



