import React from 'react';

import { Typography, Card,  Grid, useTheme } from '@mui/material';
import {TwitterTweetEmbed} from 'react-twitter-embed';
import useStyles from '../Home/HomeStyles';

const Metaverses = () => {
    let classes = useStyles();
    let theme = useTheme();
  return (
    <>
     <header align='center' style={{margin: '50px'}}> 
      <Typography variant = 'h2' style={{fontFamily: 'Courier New'}} className={classes.text}>
        Videos
      </Typography>
      <Typography variant='body1' style={{fontFamily: 'Courier New'}} className={classes.text} >
        Migration Guide, Metaverse Sneak Peeks, & More!
      </Typography>
     
      </header>
      <main>
          <Grid container align='center' style={{width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
   

    [theme.breakpoints.up(800 + theme.spacing(1))]: {
        width: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
    }
    }} >

    
      <Grid item xs={12} sm={12} md={12} lg={6}>
       
         <Card style={{backgroundColor: 'black'}}>
         <TwitterTweetEmbed
  tweetId={'1505153773508911105'}
/>
      </Card>
      </Grid>
       <Grid item xs={12} sm={12} md={12} lg={6}>
       
         <Card style={{backgroundColor: 'black'}}>
         <TwitterTweetEmbed
  tweetId={'1514338926860902400'}
/>
      </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
       
         <Card style={{backgroundColor: 'black'}}>
         <TwitterTweetEmbed
  tweetId={'1519392071756369920'}
/>
      </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
       
         <Card  style={{backgroundColor: 'black'}}>
         <TwitterTweetEmbed
  tweetId={'1524185909092093954'}
  
/>
      </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
       
       <Card  style={{backgroundColor: 'black'}}>
       <TwitterTweetEmbed
tweetId={'1525226647313690624'}

/>
    </Card>
    </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
       
         <Card  style={{backgroundColor: 'black'}}>
         <TwitterTweetEmbed
  tweetId={'1507140780955246603'}
  
/>
      </Card>
      </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
       
         <Card style={{backgroundColor: 'black'}}>
         <TwitterTweetEmbed
  tweetId={'1478660810117468163'}
/>
      </Card>
      </Grid>
         </Grid>
           </main>
    </>
  )
}

export default Metaverses